import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
  }
}

/*
export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#F22F46',
    },
  },
  sidebarWidth: 260,
});
*/

export default createMuiTheme({
  palette: {
    type: 'light',
    text: {
      primary: '#fff',
      secondary: '#000000',
      disabled: '#fff',
      hint: '#fff',
    },
    primary: {
      main: '#206F86',
      dark: 'rgb(14, 127, 150)',
    },
  },
  sidebarWidth: 260,
  typography: {
    fontFamily: ['Roboto'].join(','),
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        backgroundColor: '#f50057',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid #FFFFFF`,
        },
        '&$focused:after': {
          borderBottomColor: `#FFFFFF`,
        },
        '&$error:after': {
          borderBottomColor: `#FFFFFF`,
        },
        '&:before': {
          borderBottom: `1px solid #FFFFFF`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #FFFFFF`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #FFFFFF`,
        },
      },
    },
  },
});
