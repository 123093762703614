import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';
import { decodedJwtRoomName } from './../../jwt';
import { TwilioError } from 'twilio-video';

let themeColor = '#fff';

const TextFieldRoom = withStyles({
  root: {
    '& > *': {
      color: `${themeColor}`,
    },
    '& label.Mui-focused': {
      color: `${themeColor}`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${themeColor}`,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${themeColor}`,
      },
      '&:hover fieldset': {
        borderColor: `${themeColor}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${themeColor}`,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#17b9df',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
    formWrapper: {
      marginLeft: 'auto',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { URLRoomName } = useParams();

  const { user, getToken, isFetching, setError } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (URLRoomName) {
      let RoomName = decodedJwtRoomName(URLRoomName);
      if (RoomName) {
        setRoomName(URLRoomName);
      } else {
        setError({ message: 'Impossible join ROOM', code: 9999 } as TwilioError);
        setDisabled(true);
      }
    } else {
      setError({ message: 'Impossible join ROOM', code: 9999 } as TwilioError);
      setDisabled(true);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  /*  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };*/

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let RoomNameDecode = decodedJwtRoomName(roomName);
    if (!RoomNameDecode) {
      setError({ message: 'Impossible join ROOM', code: 9999 } as TwilioError);
      setDisabled(true);
    } /*else {
      setTitleRoom(RoomNameDecode);
    }*/
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}`));
    }
    getToken(name, RoomNameDecode).then(token => connect(token));
  };

  const inputProps = {
    required: true,
    disabled: disabled,
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar>
        {roomState === 'disconnected' ? (
          <img src="/logo_diabnext.png" alt="logo Diabnext" width={45} />
        ) : (
          <img src="/logo_diabnext_full.png" alt="logo Diabnext" width={170} />
        )}
        <div className={classes.formWrapper}>
          {roomState === 'disconnected' ? (
            <form className={classes.form} onSubmit={handleSubmit}>
              {!user?.displayName ? (
                <TextFieldRoom
                  id="menu-name"
                  label="Votre Nom *"
                  className={classes.textField}
                  value={name}
                  onChange={handleNameChange}
                  margin="dense"
                  rows={1}
                  InputProps={inputProps}
                />
              ) : (
                <Typography className={classes.displayName} variant="body1">
                  {user.displayName}
                </Typography>
              )}
              {/*              <TextFieldRoom
                id="menu-room"
                label="Room *"
                className={classes.textField}
                value={roomName}
                onChange={handleRoomNameChange}
                margin="dense"
                rows={2}
                InputProps={{
                  required: true,
                  disabled: disabled,
                  readOnly: true,
                }}
              />*/}
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isConnecting || !name || !roomName || isFetching}
              >
                Rejoindre
              </Button>
              {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
            </form>
          ) : (
            ''
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
