/*
import * from 'jsonwebtoken';
*/
import jwt from 'jsonwebtoken';

/*
declare module 'jsonwebtoken' {}
*/

interface decodeJwt {
  data: string;
  exp: number;
  iat: number;
}

export function decodedJwtRoomName(token: string): string {
  var decoded;
  let key = 'Diabnext/*-+MyLittlePony1-*/1990';
  try {
    var decodeJwt = jwt.verify(token, key);
    if (decodeJwt) {
      const newObj: any = decodeJwt;
      decoded = newObj.data;
    }
  } catch (err) {}

  return decoded;
}
